<template>
  <div id="events-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="ResultFlag">
          <v-row wrap>
            <!-- <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Status</h5>
              <v-chip
                :color="getActiveStatusColor(row.ActiveStatusTxt)"
                draggable
                dark
                >{{ row.ActiveStatusTxt }}</v-chip
              >
            </v-col> -->
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Sponsor Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Sponsor Type</h5>
              <p class="preview-content">
                {{ row.SponsorTypeTxt }} <br />
                {{ row.SponsorZoneName }} - {{ row.SponsorLomName }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Sponsor Member Name</h5>
              <p class="preview-content">
                {{ row.SponsorMemberName }} <br />
                {{ row.SponsorMemberMembershipId }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Sponsor Mobile Number</h5>
              <p class="preview-content">{{ row.OrdersMobileNo }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Sponsor Email Id</h5>
              <p class="preview-content">{{ row.OrdersEmailId }}</p>
            </v-col>
          </v-row>
          <hr />
          <br />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">LO Title Payment Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Paid Date</h5>
              <p class="preview-content">{{ row.OrdersDate }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Payment ID</h5>
              <p class="preview-content">{{ row.PaymentId }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Payment method</h5>
              <p class="preview-content">{{ row.PaymentMethod }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Paid amount</h5>
              <p class="preview-content">{{ row.TotalAmountTxt }}</p>
            </v-col>
          </v-row>
          <hr />
          <br />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">LO Title Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">New LO Title Name</h5>
              <p class="preview-content">{{ row.NewLomsName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">State</h5>
              <p class="preview-content">{{ row.NewLomStateName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">District</h5>
              <p class="preview-content">{{ row.NewLomDistrictName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">City</h5>
              <p class="preview-content">{{ row.NewLomCityName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Expiry Date</h5>
              <p class="preview-content">{{ row.ExpiryDateTxt }}</p>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
              md="3"
              v-if="row.LGBResolutionFilePath != ''"
            >
              <v-btn
                :href="row.LGBResolutionFilePath"
                target="_blank"
                color="red"
                class="mx-2"
                dark
                small
              >
                <v-icon dark> mdi-file-pdf </v-icon>LGB Resolution
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
              md="3"
              v-if="row.OrientationCertificateFilePath != ''"
            >
              <v-btn
                :href="row.OrientationCertificateFilePath"
                target="_blank"
                color="red"
                class="mx-2"
                dark
                small
              >
                <v-icon dark> mdi-file-pdf </v-icon> Orientation Certificate
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
              md="3"
              v-if="row.MembershipListFilePath != ''"
            >
              <v-btn
                :href="row.MembershipListFilePath"
                target="_blank"
                color="red"
                class="mx-2"
                dark
                small
              >
                <v-icon dark> mdi-file-pdf </v-icon> Membership List
              </v-btn>
            </v-col>
          </v-row>
          <hr />
          <br />
          <v-container v-if="row.SubscriptionPaymentFlag">
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h3 class="red--text text--lighten">New President Details</h3>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">President Type</h5>
                <p class="preview-content">
                  {{ row.PresidentsMemberTypeTxt }}
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">President Name</h5>
                <p class="preview-content">
                  {{ row.PresidentsFirstName }} <br />
                  {{ row.PresidentsMembershipId }}
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">
                  President Mobile Number
                </h5>
                <p class="preview-content">{{ row.PresidentsMobileNo }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">President Email Id</h5>
                <p class="preview-content">{{ row.PresidentsEmailId }}</p>
              </v-col>
            </v-row>
            <hr />
            <br />
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h3 class="red--text text--lighten">
                  Subscription Payment Details
                </h3>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Paid Date</h5>
                <p class="preview-content">
                  {{ row.SubscriptionOrdersDateTxt }}
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Payment ID</h5>
                <p class="preview-content">{{ row.SubscriptionPaymentId }}</p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Payment method</h5>
                <p class="preview-content">
                  {{ row.SubscriptionPaymentMethod }}
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5 class="blue--text text--lighten">Paid amount</h5>
                <p class="preview-content">
                  {{ row.SubscriptionTotalAmountTxt }}
                </p>
              </v-col>
            </v-row>
            <hr />
            <br />
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h3 class="red--text text--lighten">
                  Subscription Payment Details
                </h3>
              </v-col>
              <v-col cols="12" sm="12" lg="12" md="12">
                <v-data-table
                  class="elevation-1"
                  loading-text="Loading... Please wait"
                  disable-sort
                  disable-filtering
                  :item-key="tableOptions1.ItemKey"
                  :headers="tableColumns1"
                  :items="tableData1"
                  hide-default-footer
                >
                </v-data-table>
              </v-col>
            </v-row>
            <hr />
            <br />
          </v-container>
          <div v-if="!previewFlag">
            <v-container
              v-if="
                row.TitleApprovalFlag ||
                row.TitleConfirmationFlag ||
                row.PaymentApprovalFlag
              "
            >
              <v-row wrap>
                <v-col cols="12" sm="12" lg="12" md="12" align="center">
                  <h4 v-if="row.TitleApprovalFlag || row.TitleConfirmationFlag">
                    For New LO Title administrative purpose only
                  </h4>
                  <h4 v-if="row.PaymentApprovalFlag">
                    For New LO Subscription Payment administrative purpose only
                  </h4>
                </v-col>
              </v-row>
              <v-form
                ref="form1"
                v-model="valid1"
                lazy-validation
                v-on:submit.prevent="searchForm"
              >
                <v-row wrap v-if="row.TitleApprovalFlag">
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> New LO Title Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="NewLoName"
                      :rules="NewLoNameRules"
                      hint="Enter the New LO Title Name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" lg="8" md="8">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Approval Description
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ApprovalDescription"
                      :rules="ApprovalDescriptionRules"
                      hint="Enter the Description"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap v-if="row.TitleConfirmationFlag">
                  <v-col cols="12" sm="12" lg="12" md="12">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Confirmation
                        Description
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ApprovalDescription"
                      :rules="ApprovalDescriptionRules"
                      hint="Enter the Description"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap v-if="row.PaymentApprovalFlag">
                  <v-col cols="12" sm="3" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Provisional Date
                      </h6>
                    </label>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="ProvisionalDate"
                          label="Provisional Date"
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="
                            form-control form-control-lg form-control-solid
                          "
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ProvisionalDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="9" lg="9" md="9">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Approval Description
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ApprovalDescription"
                      :rules="ApprovalDescriptionRules"
                      hint="Enter the Description"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      v-if="row.TitleConfirmationFlag"
                      @click.prevent="confirmAlert(1)"
                      :loading="ApproveLoadingFlag"
                      color="success"
                      elevation="30"
                      shaped
                      tile
                      large
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Confirmation
                    </v-btn>
                    <v-btn
                      v-else
                      @click.prevent="confirmAlert(1)"
                      :loading="ApproveLoadingFlag"
                      color="success"
                      elevation="30"
                      shaped
                      tile
                      large
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Approve
                    </v-btn>
                    <v-btn
                      @click.prevent="confirmAlert(2)"
                      :loading="ApproveRejectLoadingFlag"
                      color="warning"
                      elevation="30"
                      shaped
                      tile
                      large
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Reject
                    </v-btn>
                    <v-btn
                      @click="closePrompt"
                      color="primary"
                      elevation="30"
                      shaped
                      tile
                      large
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewFlag: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      ResultFlag: false,
      LoadingFlag: false,
      row: {},
      PaymentDetails: {},
      selected: [],

      NewLoName: "",
      NewLoNameRules: [(v) => !!v || "Description is required"],
      ApprovalDescription: "",
      ApprovalDescriptionRules: [(v) => !!v || "Description is required"],

      ConfirmationDescription: "",
      ConfirmationDescriptionRules: [(v) => !!v || "Description is required"],

      ApproveLoadingFlag: false,
      ApproveRejectLoadingFlag: false,
      ConfirmationLoadingFlag: false,
      ConfirmationRejectLoadingFlag: false,

      ZoneCode: "",
      LomCode: "",

      tableColumns1: [],
      tableOptions1: {},
      tableData1: [],

      ProvisionalDate: new Date().toISOString().slice(0, 10),
      menu1: false,
    };
  },
  computed: {},
  watch: {
    NewLoName: function () {
      console.log("NewLoName watch");
      this.NewLoName = this.NewLoName.toUpperCase();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      var previewFlag = this.previewFlag;
      if (previewFlag) {
        this.$emit("hidePreviewRecordPrompt");
      } else {
        this.$emit("hideEditRecordPrompt");
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var message = "";

      var previewFlag = this.previewFlag;
      console.log({ previewFlag });

      var UserInterface = previewFlag ? 1 : 2;
      console.log({ UserInterface });

      var tr = this.recordData;
      console.log({ tr });

      var OrderId = tr.JmOrdersId;
      var NewLomsId = tr.JmNewLomsId;

      if (OrderId != "" && NewLomsId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/new-lom/show";
        var upload = {
          UserInterface: UserInterface,
          Order: OrderId,
          NewLom: NewLomsId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.row = {};
        this.ResultFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.Documentpath = "";

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            console.log({ flag });

            output = response.data.output;
            console.log({ output });

            records = response.data.records;
            console.log({ records });

            thisIns.ResultFlag = flag;

            var Subscribtions = records.Subscribtions;
            console.log({ Subscribtions });

            if (flag == 1) {
              thisIns.row = records;
              thisIns.NewLoName = records.NewLomsName;
              thisIns.tableColumns1 = Subscribtions.TableHeader;
              thisIns.tableData1 = Subscribtions.TableData;
              thisIns.tableOptions1 = Subscribtions.TableOptions;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            // thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        if (OrderId == "") {
          message += "Order details are invalid. ";
        }
        if (NewLomsId == "") {
          message += "New LOM details are invalid. ";
        }
        this.toast("error", message);
      }
    },
    confirmAlert(status) {
      console.log("confirmAlert is called");
      console.log({ status });
      var htmlTxt = "";

      var validate = this.$refs.form1.validate();
      console.log({ validate });

      if (validate) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            switch (status) {
              case 1:
                this.approvalSubmitForm(1);

                break;

              case 2:
                this.approvalSubmitForm(2);

                break;

              default:
                break;
            }
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      }
    },
    approvalSubmitForm(status) {
      console.log("approvalSubmitForm is called");

      console.log({ status });

      var tr = this.row;
      console.log({ tr });

      this.ProgressLoadingFlag = true;

      var OrderId = tr.JmOrdersId;
      console.log({ OrderId });

      var NewLomsId = tr.JmNewLomsId;
      console.log({ NewLomsId });

      var NewPresidentsId = tr.JmPresidentsId;
      console.log({ NewPresidentsId });

      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var OrdersType = tr.OrdersType;
      console.log({ OrdersType });

      var server_url = companyConfig.apiURL;
      var add_url1 = server_url + "api/new-lom-title/approval-status-update";
      var add_url2 = server_url + "api/new-lom-payment/approval-status-update";

      var add_url = OrdersType == 3 ? add_url1 : add_url2;
      console.log({ add_url });

      var UserInterface = OrdersType == 4 ? 1 : tr.TitleApprovalFlag ? 1 : 2;
      console.log({ UserInterface });

      var upload = {
        UserInterface: UserInterface,
        NewLoName: this.NewLoName,
        Order: OrderId,
        NewLom: NewLomsId,
        NewPresident: NewPresidentsId,
        Description: this.ApprovalDescription,
        ProvisionalDate: this.ProvisionalDate,
        Status: status,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;
          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$emit("hideEditRecordPrompt");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.ProgressLoadingFlag = true;
        });
    },
  },
  beforeMount() {
    var ZoneCode = this.$session.get("ZoneId");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    console.log({ ZoneCode });
    this.ZoneCode = ZoneCode;

    var LomCode = this.$session.get("LomId");
    LomCode = LomCode == (null || undefined) ? "" : LomCode;
    console.log({ LomCode });
    this.LomCode = LomCode;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#events-preview {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>